<template>
  <div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <table class="w-full" aria-describedby="checker">
        <thead>
          <tr>
            <th scope="col" class="p-2" colspan="12">
              <p class="text-sm font-medium text-left text-gray">Checker</p>
            </th>
          </tr>
          <tr class="bg-primary-lightest">
            <th v-if="checkerGsit?.length" scope="col" :colspan="12/checkerMap?.length" :class="`${widthChecker}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">GSIT</p>
            </th>
            <th v-if="checkerDlog?.length" scope="col" :colspan="12/checkerMap?.length" :class="`${widthChecker} ${checkerGsit?.length? 'border-l': ''}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">DLOG</p>
            </th>
            <th v-if="checkerUkkp?.length" scope="col" :colspan="12/checkerMap?.length" :class="`${widthChecker} ${checkerDlog?.length || checkerGsit?.length? 'border-l': ''}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row,i in rowChecker" :key="i">
            <td class="p-2 border-gray-light" :class="{ 'border-l': j > 0, 'border-t': i > 0 }" :colspan="12/checkerMap?.length" v-for="col,j in checkerMap" :key="j">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ col[i]?.name }}</p>
              <p v-if="col[i]?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(col[i]?.approvalStatus)">{{ mapTextStatus(col[i]?.approvalStatus)}}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(col[i]?.approvalDate) }}</span> 
              </p>
              <p v-else-if="waitingApproval.includes(col[i]?.username)" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th scope="col" class="p-2 border-t border-gray-light" colspan="12">
              <p class="text-sm font-medium text-left text-gray">Recommendation</p>
            </th>
          </tr>
          <tr class="bg-primary-lightest">
            <th v-if="recommendationGsit?.length" scope="col" :colspan="12/recommendationMap?.length" :class="`${widthRecommendation}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">GSIT</p>
            </th>
            <th v-if="recommendationDlog?.length" scope="col" :colspan="12/recommendationMap?.length" :class="`${widthRecommendation} ${recommendationGsit?.length? 'border-l': ''}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">DLOG</p>
            </th>
            <th v-if="recommendationUkkp?.length" scope="col" :colspan="12/recommendationMap?.length" :class="`${widthRecommendation} ${recommendationDlog?.length || recommendationGsit?.length? 'border-l': ''}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row,i in rowRecommendation" :key="i">
            <td class="p-2 border-gray-light" :class="{ 'border-l': j > 0, 'border-t': i > 0 }" :colspan="12/recommendationMap?.length" v-for="col,j in recommendationMap" :key="j">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ col[i]?.name }}</p>
              <p v-if="col[i]?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(col[i]?.approvalStatus)">{{ mapTextStatus(col[i]?.approvalStatus) }}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(col[i]?.approvalDate) }}</span> 
              </p>
              <p v-else-if="waitingApproval.includes(col[i]?.username)" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th scope="col" class="p-2 border-t border-gray-light" colspan="12">
              <p class="text-sm font-medium text-left text-gray">Approval</p>
            </th>
          </tr>
          <tr class="bg-primary-lightest">
            <th scope="col" colspan="12" class="w-full p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-center text-gray">Management</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row,i in approverManagement" :key="i">
            <td class="p-2 border-gray-light" v-for="col,j in row" :key="j" 
              :class="`${borderR(i,j,row)} ${i !== approverManagement.length-1? 'border-b' : ''} ${widthApprover}`" 
              :colspan="approverManagement[0]?.length < 3? 12/approverManagement[0]?.length : 4" >
              <div class="flex">
                <div class="flex-auto">
                  <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ col?.name }}</p>
                  <p v-if="col?.approvalDate"
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span v-if="col?.requiredUploadSignedApproval && col?.approvalStatus === PROCUREMENT_APPROVED" >Uploaded on </span>
                    <span v-if="!col?.requiredUploadSignedApproval || col?.approvalStatus === PROCUREMENT_REJECTED" :class="colorTextStatus(col?.approvalStatus)">{{ mapTextStatus(col?.approvalStatus) }}</span>
                    <span v-if="!col?.requiredUploadSignedApproval || col?.approvalStatus === PROCUREMENT_REJECTED" class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ formatDate4(col?.approvalDate) }}</span> 
                  </p>
                  <p v-else-if="onReviewManagement(col?.requiredUploadSignedApproval) || waitingApproval.includes(col?.username)" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
                  <p v-else class="min-height-p"></p>
                </div>
                <div class="flex-none w-10 cursor-pointer" v-if="col?.requiredUploadSignedApproval" >
                  <img src="@/core/assets/icons/file/sign.svg" alt="sign" @click="clickSign"/>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { formatDate4 } from '@/core/components/helpers/format';
import { CHECKER, RECOMMENDATION, APPROVER, PROCUREMENT_APPROVED, PROCUREMENT_REJECTED } from '@/core/constant/procurementApproval.js'

export default {
  name: 'TableApprovalProcurement',
  data() {
    return {
      waiting: false,
      map: [
        { name: 'Checker', arrayRow: 'rowChecker', arrayMap: 'checkerMap', arrayGsit: 'checkerGsit', arrayUkkp: 'checkerGsit', arrayDlog: 'checkerDlog', },
        { name: 'Recommendation', arrayRow: 'rowRecommendation', arrayMap: 'recommendationMap', arrayGsit: 'recommendationGsit', arrayUkkp: 'recommendationGsit', arrayDlog: 'recommendationDlog', },
        { name: 'Approver', arrayRow: 'rowApprover', arrayMap: 'approverMap', arrayGsit: 'approverGsit', arrayUkkp: 'approverGsit', arrayDlog: 'approverDlog', }
      ],
      PROCUREMENT_APPROVED, 
      PROCUREMENT_REJECTED
    }
  },
  computed: {
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    waitingApproval() {
      return this.logApproval.waitingApproval?.split(',')
    },
    checker() {
      return this.logApproval?.logDetails?.filter(e => e.approvalType === CHECKER)
    },
    checkerGsit() {
      return this.checker?.filter(e => e.division === 'GSIT') || []
    },
    checkerDlog() {
      return this.checker?.filter(e => e.division === 'LOG' || e.division === 'DLOG') || []
    },
    checkerUkkp() {
      return this.checker?.filter(e => e.division === 'UKKP') || []
    },
    checkerMap() {
      const result = []
      if (this.checkerGsit?.length) {
        result.push(this.checkerGsit)
      }
      if (this.checkerDlog?.length) {
        result.push(this.checkerDlog)
      }
      if (this.checkerUkkp?.length) {
        result.push(this.checkerUkkp)
      }
      return result
    },
    rowChecker() {
      return Math.max(this.checkerGsit?.length, this.checkerDlog?.length, this.checkerUkkp?.length)
    },
    widthChecker() {
      return this.checkerMap?.length === 1? 'w-full' : `w-1/${this.checkerMap?.length}`
    },
    recommendation() {
      return this.logApproval?.logDetails?.filter(e => e.approvalType === RECOMMENDATION)
    },
    recommendationGsit() {
      return this.recommendation?.filter(e => e.division === 'GSIT') || []
    },
    recommendationDlog() {
      return this.recommendation?.filter(e => e.division === 'LOG' || e.division === 'DLOG' ) || []
    },
    recommendationUkkp() {
      return this.recommendation?.filter(e => e.division === 'UKKP') || []
    },
    recommendationMap() {
      const result = []
      if (this.recommendationGsit?.length) {
        result.push(this.recommendationGsit)
      }
      if (this.recommendationDlog?.length) {
        result.push(this.recommendationDlog)
      }
      if (this.recommendationUkkp?.length) {
        result.push(this.recommendationUkkp)
      }
      return result
    },
    rowRecommendation() {
      return Math.max(this.recommendationGsit?.length, this.recommendationDlog?.length, this.recommendationUkkp?.length)
    },
    widthRecommendation() {
      return this.recommendationMap?.length === 1? 'w-full' : `w-1/${this.recommendationMap?.length}`
    },
    approver() {
      return this.logApproval?.logDetails?.filter(e => e.approvalType === APPROVER)
    },
    approverGsit() {
      return this.approver?.filter(e => e.division === 'GSIT') || []
    },
    approverDlog() {
      return this.approver?.filter(e => e.division === 'LOG' || e.division === 'DLOG') || []
    },
    approverUkkp() {
      return this.approver?.filter(e => e.division === 'UKKP') || []
    },
    approverManagement() {
      const result = [];
      for (let i = 0; i < this.approver?.length; i += 3) {
          result.push(this.approver.slice(i, i + 3));
      }
      return result;
    },
    approverMap() {
      const result = []
      if (this.approverGsit?.length) {
        result.push(this.approverGsit)
      }
      if (this.approverDlog?.length) {
        result.push(this.approverDlog)
      }
      if (this.approverUkkp?.length) {
        result.push(this.approverUkkp)
      }
      return result
    },
    rowApprover() {
      return Math.max(this.approverGsit?.length, this.approverDlog?.length, this.approverUkkp?.length)
    },
    widthApprover() {
      return this.approverManagement[0]?.length === 1? 'w-full' : `w-1/${this.approverManagement[0].length}`
    },
  },
  methods: {
    formatDate4,
    colorTextStatus(status) {
      if (status === PROCUREMENT_APPROVED) {
        return 'text-success'
      } else {
        return 'text-error'
      }
    },
    mapTextStatus(status) {
      if (status === PROCUREMENT_APPROVED) {
        return 'Approved'
      } else {
        return 'Rejected'
      }
    },
    onReviewManagement(requiredUploadSignedApproval) {
      return this.recommendation?.every(e => e.approvalStatus === PROCUREMENT_APPROVED) && this.checker?.every(e => e.approvalStatus === PROCUREMENT_APPROVED) && requiredUploadSignedApproval && !this.dataProcurementApproval?.attachmentSignedApproval?.fileName && this.approver?.every(e => e.approvalStatus !== PROCUREMENT_REJECTED)
    },
    clickSign() {
      this.$emit('goToUploadSignApproval')
    },
    borderR(i,j,row) {
      return j !== row?.length-1 && i === 0 && (j === 0 || j === 1) ? 'border-r' : (j !== row?.length-1 || j !== 2) && i !== 0? 'border-r' : ''
    },
  },
  components: {
  }
}
</script>

<style #scoped>
.min-height-p {
  min-height: 1.25em;
}
</style>