<template>
  <div>
    <SppLoading v-if="loading.loadingSpp"></SppLoading>
    <div v-if="sppShow && !loading.loadingSpp">
      <div v-if="!isSubmitted && !isRejected && showButtonContinueDraft && !dataSpp.isNew && !mainPage && menuRfqSpp?.canCreate">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista type="success" class="w-full sm:w-56" @click="() => isOpenContinueDraft = true">Continue Draft</ButtonGista>
          <ModalConfirm 
            title="Lanjutkan Draft?" 
            :message="`Draft terakhir: ${dataSpp.lastModifiedAt}`" 
            icon="saved" textButton="Continue Draft" 
            :isOpen="isOpenContinueDraft" 
            @changeIsOpen="() => isOpenContinueDraft = false" 
            @click="setShowButtonContinueDraft(false)"
            colorButton="success">
          </ModalConfirm>
        </div>
      </div>
      <CreateSpp 
        v-if="(!showButtonContinueDraft || !dataSpp.lastModifiedAt) && !isSubmitted && !isRejected && menuRfqSpp?.canCreate" 
        @setShowButtonContinueDraft=setShowButtonContinueDraft ref="createSpp" 
      />
      <EditSpp
        v-if="mainPage === EDIT && menuRfqSpp?.canCreate"
      />
      <SppDetails v-if="(isSubmitted || isRejected) && !mainPage && menuRfqSpp?.canRead"/>
    </div>
  </div>
</template>

  <script>
  import CreateSpp from '@/core/components/rfq/spp/CreateSpp.vue'
  import EditSpp from '@/core/components/rfq/spp/EditSpp.vue'
  import SppDetails from '@/core/components/rfq/spp/SppDetails.vue'
  import ModalConfirm from '@/core/components/modal/Confirm'
  import { EDIT } from '@/core/constant/procurementApproval.js'
  import { COMPLETED } from '@/core/constant/statusProject'
  import SppLoading from '@/core/components/rfq/loading/SppLoading.vue';

  export default {
    name: "Spp",
    data() {
      return {
        showButtonContinueDraft: false,
        isOpenContinueDraft: false,
        EDIT
      };
    },
    computed: {
      projectLogRfq() {
        return this.$store.state.projectLog.projectLogRfq
      },
      projectCompleted() {
        return this.projectLogRfq.rfqLogs.some(e => e.statusProject === COMPLETED)
      },
      sppShow() {
        return this.menuRfqSpp?.canRead && !this.projectCompleted
      },
      dataSpp: {
        get() {
          return this.$store.state.sppRfq.dataSpp
        },
        set(value) {
          this.$store.commit('sppRfq/SET_DATA_SPP', value)
        }
      },
      isSubmitted() {
        return this.dataSpp.statusDocument !== 'SPP_DRAFT' && this.dataSpp.statusDocument !== null
      },
      isRejected() {
        return this.dataSpp.approvals?.some(e => e.approvalStatus === 'REJECT')
      },
      mainPage() {
        return this.$store.state.sppRfq.mainPage
      },
      loading: {
        get() {
          return this.$store.state.modal.loading
        },
        set(value) {
          this.$store.commit('modal/SET_LOADING', value)
        }
      },
      isNew() {
        return !this.dataSpp.lastModifiedAt
      },
      cancelQuotation() {
        return this.$store.state.sppRfq.showSpp
      },
      menuRfqSpp() {
        return this.$store.getters['dashboard/menuRfqSpp']
      },
      counterInterval() {
        return this.$store.state.projectLog.counterInterval
      },
    },
    methods: {
      setShowButtonContinueDraft(e) {
        this.showButtonContinueDraft = e
      },
      getSpp() {
        this.$store.dispatch('sppRfq/getSpp', { rfqId: this.$route.params.quotationId })
      }
    },
    async mounted() {
      
      this.loading.loadingProjectLog = true
      this.loading.loadingSpp = true
      const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.quotationId, projectRequestType: 'rfq' })
      if (res?.data.code !== 200) return
      const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfqId', { rfqId: this.$route.params.quotationId })
      if (res1?.status === 200) {
        const res3 = await this.$store.dispatch('sppRfq/getSpp', { rfqId: this.$route.params.quotationId })
        if (res3?.status === 200) {
          const data = this.dataSpp
          if (!data.isNew || this.isSubmitted) {
            this.setShowButtonContinueDraft(true)
          }
          if((data?.isNew && !this.cancelQuotation && this.menuRfqSpp?.canCreate) || this.projectCompleted) {
            this.$router.push({ name: 'QuotationDetails'}).catch(() => ({}))
          }
        }
        this.loading.loadingSpp = false
        this.loading.loadingProjectLog = false
      }
    },
    destroyed() {
      this.$store.dispatch('sppRfq/resetState', { resetAll: true })
      clearInterval(this.counterInterval)
		  this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
    },
    components:{
      CreateSpp,
      SppDetails,
      ModalConfirm,
      EditSpp,
      SppLoading
    }
  }
  </script>

  <style scoped>
  .height-custom {
      min-height: 30rem;
  }
  </style>