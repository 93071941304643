<template>
  <div>
    <div v-for="n, i in fields" :key="i" class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse(n.name.toLocaleLowerCase())">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">{{n.name.toLocaleUpperCase()}} &nbsp;</h5>
          <span v-if="!n.optional" class="text-error">*</span>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse[n.name.toLocaleLowerCase()] ? 'rotate-180' : 'rotate-0'"></p>
          
        </div>
      </div>
      <div v-show="collapse[n.name.toLocaleLowerCase()]">
        <div class="mb-3.5" v-for="value, j in data(n)" :key="j">
          <div
            class="block pr-4 mb-1 text-sm truncate md:text-left"
            :class="errorRecommendation[n.name] && j === 0 ? 'text-error' : 'text-gray'"
          >
          {{ `${n.name} ${j + 1}` }} <span v-if="(!n.optional && j === 0)" class="text-error">*</span>
          </div>
          <div class="flex w-full">
            <div class="w-full">
              <AutocompleteNewSolutip
                v-if="!disabledInput(n, value)"
                placeholder="Search"
                :options="recommendation"
                @updateInput="inputSearch(n, value)"
                @updateAutoComplete="changeRecommendation($event, n, value)"
                @focus="focusRecommendation(n, value)"
                @blur="blurRecommendation(n, value)"
                v-model="value.search"
                :isLoading="isLoading"
                textField="name"
                textField2="jobTitle"
                iconLeft="icon-search text-sm text-gray-light"
                :error="checkError(n, value)"
              >
              </AutocompleteNewSolutip>
              <InputDisabled v-else
                :value="value.search"
              />
            </div>
            <div v-if="showDeleteBtn(n) && !disabledInput(n, value)" class="flex items-center">
              <p @click="clickDelete(n, value)" class="ml-3 text-xl cursor-pointer icon-x-circle text-error"></p>
            </div>
          </div>
          <div v-if="checkError(n, value)">
            <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </div>
        </div>
        <div class="w-max" v-if="maxFields(n) && !disabledAdd(n)">
          <div @click="handleAdd(n)" class="flex items-center cursor-pointer">
            <span class="mr-2 icon-plus-square text-secondary"></span>
            <p 
              class="mr-4 text-base font-medium underline text-secondary">
              Add
            </p>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm 
      title="Hapus Approver?" 
      message="Apakah Anda yakin?" 
      icon="rejected" 
      textButton="Delete" 
      :isOpen="isOpenDelete" 
      @changeIsOpen="() => isOpenDelete = false" 
      @click="handleDelete(itemDelete, valueDelete)"
      colorButton="error"></ModalConfirm>
  </div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import ModalConfirm from '@/core/components/modal/Confirm'
import { CHECKER, RECOMMENDATION, APPROVER, PROCUREMENT_APPROVED, CHANGE } from '@/core/constant/procurementApproval.js'

export default {
  name: "Recommendation",
  props: ['collapse'],
  data() {
    return {
      recommendation: [],
      isLoading: false,
      fields: [
        {name: 'Checker', optional: true, field: 'selectedChecker' },
        {name: 'Recommendation', field: 'selectedRecommendation' },
        {name: 'Approver', field: 'selectedApprover' },
      ],
      MESSAGE_EMPTY_FIELD,
      isOpenDelete: false,
      itemDelete: null,
      valueDelete: null,
    }
  },
  computed: {
    procurementApproval() {
      return this.$store.state.procurementApprovalRfq
    },
    dataProcurementApproval() {
      return this.$store.state.procurementApprovalRfq.dataProcurementApproval
    },
    errorRecommendation: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorRecommendation
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_ERROR_RECOMMENDATION', value)
      }
    },
    selectedChecker: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedChecker
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_CHECKER', value)
      }
    },
    selectedRecommendation: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedRecommendation
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_RECOMMENDATION', value)
      }
    },
    negotiationResult() {
      return this.dataProcurementApproval.negotiationResult
		},
    selectedApprover: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedApprover
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_APPROVER', value)
      }
    },
    isRejected() {
      return this.dataProcurementApproval.isRejected
    },
    mainPage() {
      return this.$store.state.procurementApprovalRfq.mainPage
    },
    logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    waitingApproval() {
      return this.logApproval?.waitingApproval?.split(',')
    },
    dataProcurementApprovalChecker() {
      return this.dataProcurementApproval.approvalList?.filter(e => e.approvalType === CHECKER)
    },
    dataProcurementApprovalRecommendation() {
      return this.dataProcurementApproval.approvalList?.filter(e => e.approvalType === RECOMMENDATION)
    },
    dataProcurementApprovalApprover() {
      return this.dataProcurementApproval.approvalList?.filter(e => e.approvalType === APPROVER)
    },
    waitingApprovalChecker() {
      return this.dataProcurementApprovalChecker?.some(e => this.waitingApproval?.includes(e.username))
    },
    waitingApprovalRecommendation() {
      return this.dataProcurementApprovalRecommendation?.some(e => this.waitingApproval?.includes(e.username))
    },
    waitingApprovalApprover() {
      return this.dataProcurementApprovalApprover?.some(e => this.waitingApproval?.includes(e.username))
    },
  },
  methods: {
    checkError(item, value) {
      const indexValue = this.data(item).findIndex(e => e.id ? e.id === value.id : e.tempId === value.tempId)
      return indexValue === 0 && this.errorRecommendation[item.name]
    },
    selectedLooping(name) {
      return this[name]
    },
    clickDelete(item, value) {
      if (this.mainPage === CHANGE && value.id) {
        this.isOpenDelete = true
        this.itemDelete = item
        this.valueDelete = value
      } else {
        this.handleDelete(item, value)
      }
    },
    handleDelete(item, value){
      const { field } = item
      const indexValue = this[field].findIndex(e => e.userName === value.userName)
      if (value.id) {
        this[field][indexValue].isDeleted = true
      } else {
        this[field] = this[field].filter(e => value.userName? e.userName !== value.userName : e.tempId !== value.tempId )
      }
    },
    handleAdd(item) {
      const { field, name } = item
      const tempId = Date.now().toString(36)
      this[field].push({ name: '', search: '', userName: '', approvalType: name.toUpperCase(), approvalStatus: null, isDeleted: false, id: null, tempId })
    },
    maxFields(item) {
      const { field } = item
      return this[field]?.filter(e => !e.isDeleted).length < 10
    },
    showDeleteBtn(item) {
      return this.data(item).length !== 1 || item.name === this.fields[0].name
    },
    async getUserApproverProcurement(payload) {
      const  { approvalId, approvalType, nominalProject, nameFilter, usernameList } = payload
      this.isLoading = true
      const response = await this.$store.dispatch('procurementApprovalRfq/getUserApproverProcurement',  { approvalId, approvalType, nominalProject, nameFilter, usernameList })
      if (response?.data.result) {
        this.recommendation = response.data.result
        this.isLoading = false
      }
    },
    focusRecommendation(item, value) {
      const { field, name } = item
      const indexValue = this[field].findIndex(e => e.userName === value.userName)
      this.getUserApproverProcurement({approvalId: '', approvalType: name?.toUpperCase(), nominalProject: this.negotiationResult.negotiationResultDetails.grandTotalIdr, nameFilter: this[field][indexValue].search, usernameList: this.usernameListAll() })
    },
    changeRecommendation(e, item, value) {
      const {field, name} = item
      const indexValue = this[field].findIndex(el => value.userName? el.userName === value.userName : el.tempId === value.tempId)
      this.errorRecommendation[name] = false
      this[field] = this[field].map((l, idx) => idx === indexValue ? ({ ...e, search: e.name, id: l.id, approvalType: name.toUpperCase(), approvalStatus: l.approvalStatus, isDeleted: l.isDeleted }) : l )
    },
    inputSearch(item, value) {
      const { field, name } = item
      const indexValue = this[field].findIndex(el => el.userName === value.userName)
      this.getUserApproverProcurement({approvalId: '', approvalType: name?.toUpperCase(), nominalProject: this.negotiationResult.negotiationResultDetails.grandTotalIdr, nameFilter: this[field][indexValue].search, usernameList: this.usernameListAll() })
    },
    usernameListAll() {
      return [...this.selectedChecker.filter(e => e.userName && !e.isDeleted).map(e => e.userName), ...this.selectedRecommendation.filter(e => e.userName && !e.isDeleted).map(e => e.userName), ...this.selectedApprover.filter(e => e.userName && !e.isDeleted).map(e => e.userName)].join(',')
    },
    blurRecommendation(item, value) {
      const {field} = item
      const indexValue = this[field].findIndex(e => e.userName === value.userName)
      this[field] = this[field].map((l, idx) => idx === indexValue ? ({ ...l, search: l.name }) : l  )
      this.recommendation = []
    },
    changeCollapse(key) {
      this.$emit('changeCollapse', key)
    },
    data(n) {
      return this.procurementApproval[n.field]?.filter(e => !e.isDeleted)
    },
    disabledInput(item, value) {
      const {field} = item
      if (field === 'selectedChecker') {
        return (value?.approvalStatus === PROCUREMENT_APPROVED || (this.dataProcurementApprovalChecker?.every(e => e.approvalStatus === PROCUREMENT_APPROVED) && this.dataProcurementApprovalChecker?.length > 0) || this.dataProcurementApprovalRecommendation?.some(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
      if (field === 'selectedRecommendation') {
        return (value?.approvalStatus === PROCUREMENT_APPROVED || this.dataProcurementApprovalRecommendation?.every(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
      if (field === 'selectedApprover') {
        return (value?.approvalStatus === PROCUREMENT_APPROVED || this.dataProcurementApprovalApprover?.every(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
    },
    disabledAdd(item) {
      const {field} = item
      if (field === 'selectedChecker') {
        return (this.dataProcurementApprovalChecker?.some(e => e.approvalStatus === PROCUREMENT_APPROVED) || this.dataProcurementApprovalRecommendation?.some(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
      if (field === 'selectedRecommendation') {
        return (this.dataProcurementApprovalRecommendation?.some(e => e.approvalStatus === PROCUREMENT_APPROVED) || this.dataProcurementApprovalApprover?.some(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
      if (field === 'selectedApprover') {
        return (this.dataProcurementApprovalApprover?.some(e => e.approvalStatus === PROCUREMENT_APPROVED)) && !this.isRejected && this.dataProcurementApproval.isSubmitted
      }
    }
  },
  components:{
    AutocompleteNewSolutip,
    ModalConfirm,
    InputDisabled
  }
}
</script>