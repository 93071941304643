<template>
  <div>
    <ButtonGista :class="quotationDetailContainerWidth < 640 ? 'w-full' : 'w-max'" type="primary" color="success" @click="changeIsOpen(true)" :disabled="disabled">Send Email</ButtonGista>
    <ModalConfirm 
      title="Apakah anda yakin?" 
      message="Pastikan seluruh informasi sudah benar" 
      textButton="Send Email to vendor" 
      :isOpen="isOpenModalSendEmail" 
      @changeIsOpen="changeIsOpen($event)" 
      :isBackButton="true"
      @click="clickReviseOffer"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
  import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'SendEmailReviseOfferRfq',
    props: ['isOpenModalSendEmail', 'disabled'],
    computed: {
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
    },
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      },
      clickReviseOffer() {
        this.$emit('click')
      },
    },
    components: {
      ModalConfirm
    }
  }
</script>