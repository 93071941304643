<template>
  <div>
    <LoiLoading v-if="loading.loadingLoi"></LoiLoading>
    <div v-if="loiShow && !loading.loadingLoi">
      <div v-if="!isNew && !isSubmitted && !isRejected && showButtonContinueDraft && menuRfqLoi?.canCreate && !mainPage">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista type="success" @click="() => isOpenContinueDraft = true">Continue Draft</ButtonGista>
          <ModalConfirm 
            title="Lanjutkan Draft?" 
            :message="`Draft terakhir: ${draftLoi.lastModifiedAt}`"
            icon="saved" textButton="Continue Draft" 
            :isOpen="isOpenContinueDraft" 
            @changeIsOpen="() => isOpenContinueDraft = false" 
            @click="setShowButtonContinueDraft(false)"
            colorButton="success">
          </ModalConfirm>
        </div>
      </div>
      <div v-if="!sppProcess">
        <CreateLoi 
          v-if="(!showButtonContinueDraft || isNew) && !showNeedToCreateLoi && !isSubmitted && !isRejected && menuRfqLoi?.canCreate && !mainPage"
          @setShowButtonContinueDraft="setShowButtonContinueDraft" 
        />
        <EditLoi 
          v-if="menuRfqLoi?.canCreate && mainPage === EDIT"
        />
        <NeedToCreateLoi :loiNo="loiNo" @click="clickNeedToCreateLoi" v-if="!isSubmitted && menuRfqLoi?.canUpdate && showNeedToCreateLoi" />
      </div>
      <div v-else-if="!isSubmitted && !isRejected"
        class="flex items-center justify-center height-custom">
        <div class="flex">
          <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
          <p class="text-base font-medium text-gray">Proses SPP sedang di mulai</p>
        </div>
      </div>
      <LoiDetails v-if="(isSubmitted || isRejected) && !mainPage"/>
    </div>
    <div v-if="!loiShow && !loading.loadingLoi"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Proses LOI belum di mulai</p>
      </div>
    </div>
    <div v-if="loiShow && !menuRfqLoi?.canCreate && !completedNoLoi && !isSubmitted && !isRejected && !loading.loadingLoi"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Menunggu kelengkapan form LOI</p>
      </div>
    </div>
    <div v-if="loiShow && completedNoLoi && !loading.loadingLoi" class="flex items-center justify-center height-custom">
      <div class="flex flex-col items-center gap-3">
        <img src="@/core/assets/icons/file/file.svg" alt="icon" class="w-28"/>
        <div class="flex items-center gap-2 border rounded-md py-2.5 px-3.5 border-gray-lightest">
          <span class="text-lg font-medium icon-check-circle text-gray"></span>
          <p class="text-lg font-medium text-gray">LOI will not be created</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOI_AWAITING_FOR_CREATION } from '@/core/constant/statusProject'
import CreateLoi from '@/core/components/rfq/loi/CreateLoi.vue'
import EditLoi from '@/core/components/rfq/loi/EditLoi.vue'
import LoiDetails from '@/core/components/rfq/loi/LoiDetails.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import LoiLoading from '@/core/components/rfq/loading/LoiLoading.vue'
import NeedToCreateLoi from '@/core/components/rfq/loi/NeedToCreateLoi.vue'
import { EDIT } from '@/core/constant/procurementApproval.js'

export default {
  name: "Loi",
  data() {
    return {
      showButtonContinueDraft: false,
      isOpenContinueDraft: false,
      EDIT
    };
  },
  computed: {
    projectLogRfq() {
      return this.$store.state.projectLog.projectLogRfq
    },
    loiShow() {
      const statusProject = [ LOI_AWAITING_FOR_CREATION ]
      return this.projectLogRfq?.rfqLogs?.some(e => statusProject.includes(e.statusProject))
    },
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_LOI', value)
      }
    },
    isSubmitted() {
      return !!(this.draftLoi.statusDocument === 'SUBMITTED')
    },
    isRejected() {
      return this.draftLoi.approvals?.some(e => e.approvalStatus === 'REJECT')
    },
    isNew() {
      return !this.draftLoi.lastModifiedAt
    },
    loiNo() {
      return this.draftLoi.loiNo
    },
    menuRfqLoi() {
			return this.$store.getters['dashboard/menuRfqLoi']
		},
    menuRfqLoiSubmit() {
			return this.$store.getters['dashboard/menuRfqLoiSubmit']
		},
    mainPage() {
      return this.$store.state.loiRfq.mainPage
    },
		sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    loading: {
      get() {
        return this.$store.state.modal.loading
      },
      set(value) {
        this.$store.commit('modal/SET_LOADING', value)
      }
    },
    showNeedToCreateLoi: {
      get() {
        return this.$store.state.loiRfq.showNeedToCreateLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_SHOW_NEED_LOI', value)
      }
    },
    completedNoLoi() {
      return this.draftLoi.isCompleted && this.draftLoi.statusDocument !== 'SUBMITTED'
    },
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
  },
  methods: {
    setShowButtonContinueDraft(e) {
      this.showButtonContinueDraft = e
    },
    refetchLoiRfqLog() {
      this.$store.dispatch('projectLog/refetchLoiRfqLog', { rfqId: this.$route.params.quotationId })
    },
    setShowNeedToCreateLoi(e) {
      this.showNeedToCreateLoi = e
    },
    async clickNeedToCreateLoi(val) {
      if(val) this.setShowNeedToCreateLoi(false)
      else {
        this.modal.modalLoading = true
        await this.$store.dispatch('loiRfq/needToCreateLoi', { rfqId: this.$route.params.quotationId })
        this.modal.modalLoading = false
        this.refetchLoiRfqLog()
      }
    }
  },
  async mounted() {
    this.isLoading = true
    this.loading.loadingProjectLog = true
    this.loading.loadingLoi = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.quotationId, projectRequestType: 'rfq' })
    if (res?.data.code !== 200) return
    const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfqId', { rfqId: this.$route.params.quotationId })
    if (res1?.status === 200 && this.loiShow) {
      this.loading.loadingLoi = false
      const res2 = await this.$store.dispatch('loiRfq/getLoi', { rfqId: this.$route.params.quotationId })
      if (res2?.status === 200) {
        if (!this.isNew) {
          this.setShowButtonContinueDraft(true)
        }
      }
    } else this.loading.loadingLoi = false
    this.loading.loadingProjectLog = false
  },
  destroyed() {
    this.$store.dispatch('loiRfq/resetState')
    clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
  },
  components:{
    CreateLoi,
    LoiDetails,
    ModalConfirm,
    EditLoi,
    LoiLoading,
    NeedToCreateLoi
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>