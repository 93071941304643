<template>
	<div class="">
		<div class="w-full" :class="quotationDetailContainerWidth < 1250? 'overflow-x-auto' : ''">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="costBudgetInformation">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th v-if="(!isSubmitted || isRejected) && !readOnly" class="p-0" scope="col">
								<div class="h-10 border-b-2 border-primary"></div>
							</th>
							<th class="p-0" scope="col" >
								<h5 class="flex items-center min-w-80 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-2.5">Cost Center <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-error">*</span></h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-80 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">WBS Lev 3 Code</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-80 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">COA</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-52 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">Budget Value (IDR) <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-error">*</span></h5>
							</th>
						</tr>
					</thead>
					<tbody>
            <tr v-for="n,i in dataProcurementApproval?.costBudgetInformationList" :key="i" class="truncate border-b border-gray-lightest">
              <template v-if="!n.isDeleted">
                <td v-if="(!isSubmitted || isRejected) && !readOnly">
                  <div v-if="showBtnDel" class="flex items-center px-1">
                    <p class="text-xl cursor-pointer icon-x text-error" @click="removeRow(i)" ></p>
                  </div>
                </td>
                <td class="pr-3.5 pl-2.5 py-2 align-top">
                  <div v-if="(!isSubmitted || isRejected) && !readOnly">
                    <AutocompleteNewSolutip
                      placeholder="Search"
                      size="small"
                      :options="optionsCostCenter"
                      @updateInput="inputSearchCostCenter($event, 'costCenterId')"
                      @updateAutoComplete="changeSearchCostCenter($event, i, n)"
                      @focus="focusSearch('costCenterId', i)"
                      @blur="blurSearchCostCenter(i)"
                      v-model="n.costCenterSearch"
                      textField="costCenterId"
                      textField2="description"
                      iconLeft="icon-search text-sm text-gray-light"
                      :error="!!n.error?.['Cost Center']"
                      :isAbsoluteList="false"
                    >
                      <template #message v-if="!!n.error?.['Cost Center']">
                        <p>{{ n.error?.['Cost Center'] }}</p>
                      </template>
                    </AutocompleteNewSolutip>
                  </div>
                  <div v-else>
                    <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.costCenterId }}</h5>
                  </div>
                </td>
                <td class="px-3.5 py-2 align-top">
                  <div class="flex flex-col" v-if="(!isSubmitted || isRejected) && !readOnly">
                    <AutocompleteNewSolutip
                      placeholder="Search"
                      size="small"
                      :options="optionsWbsLev3Code"
                      @updateInput="inputSearchWbsLev3Code($event, 'wbsElement')"
                      @updateAutoComplete="changeSearchWbsLev3($event, i, n)"
                      @focus="focusSearch('wbsElement', i)"
                      @blur="blurSearchWbsElement(i)"
                      v-model="n.wbsElementSearch"
                      textField="name"
                      textField2="wbsElement"
                      iconLeft="icon-search text-sm text-gray-light"
                      :isAbsoluteList="false"
                    >
                    </AutocompleteNewSolutip>
                    <span class="mt-1 text-xs font-normal text-gray">{{ n.wbsName }}</span>
                  </div>
                  <div v-else>
                    <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.wbsElement }}</h5>
                  </div>
                </td>
                <td class="px-3.5 py-2 align-top">
                  <div class="flex flex-col" v-if="(!isSubmitted || isRejected) && !readOnly">
                    <AutocompleteNewSolutip
                      placeholder="Search"
                      size="small"
                      :options="optionsCoa"
                      @updateInput="inputSearchCoa($event, 'coaCode')"
                      @updateAutoComplete="changeSearchCoa($event, i, n)"
                      @focus="focusSearch('coaCode', i)"
                      @blur="blurSearchCoaCode(i)"
                      v-model="n.coaCodeSearch"
                      textField="coaCode"
                      textField2="description"
                      iconLeft="icon-search text-sm text-gray-light"
                      :isAbsoluteList="false"
                    >
                    </AutocompleteNewSolutip>
                    <span class="mt-1 text-xs font-normal text-gray">{{n.coaDescription}}</span>
                  </div>
                  <div v-else>
                    <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.coaCode }}</h5>
                  </div>
                </td>
                <td class="px-3.5 py-2 align-top">
                  <div v-if="(!isSubmitted || isRejected) && !readOnly">
                    <InputExtraSmall 
                      :widthFull="true" 
                      v-model="n.budgetValueIdr" 
                      @keypress="validationNumber"
                      @input="inputBudgetValue($event, i)"
                      @handleInput="handleInputBudgetValue($event, i)"
                      :error="!!n.error?.['Budget Value']" 
                      bgColor="bg-white" 
                      align="text-right">
                      <template #message v-if="!!n.error?.['Budget Value']">
                        <p>{{ n.error?.['Budget Value'] }}</p>
                      </template>
                    </InputExtraSmall>
                  </div>
                  <div v-else>
                    <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.budgetValueIdr }}</h5>
                  </div>
                </td>
              </template>
            </tr>
            <tr v-if="quotationDetailContainerWidth < 1250 && addHeight" :class="addHeight"></tr>
            <tr v-if="(!isSubmitted || isRejected) && !readOnly && maxRows" class="h-10 truncate border-b border-gray-lightest">
              <td colspan="2">
                <div class="flex">
                  <div class="flex items-center cursor-pointer" @click="addRow()">
                    <span class="mr-2 icon-plus-square text-secondary"></span>
                    <p 
                      class="mr-4 text-base font-medium underline text-secondary">
                      Add
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="border-b-2 bg-gradient-to-t border-primary from-primary-lightest to-white">
              <td class="p-0" colspan="5">
                <div class="flex items-center h-10 ">
                  <p class="flex items-center h-10 text-sm font-medium text-left text-gray-darkest pl-2.5 pr-3.5">Total Budget:</p>
                  <p class="flex items-center h-10 text-sm font-medium text-left text-success-dark">{{ formatCurrency(totalBudget()) }}</p>
                </div>
              </td>
            </tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency,parseLocaleNumber } from '@/core/components/helpers/formatRupiah'
import InputExtraSmall from '@/core/components/custom/InputExtraSmall.vue'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'

export default {
	name: 'CostBudgetInformation',
  props: ['readOnly'],
	data() {
		return {
      optionsCostCenter: [],
      optionsWbsLev3Code: [],
      optionsCoa: [],
      selectedCostCenter: '',
      addHeight: '',
    }
	},
  computed: {
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    maxRows() {
      return this.dataProcurementApproval.costBudgetInformationList?.filter(e => !e.isDeleted).length < 15
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval?.isRejected
    },
    showBtnDel() {
      return this.dataProcurementApproval.costBudgetInformationList?.filter(e => !e.isDeleted).length > 1
    },
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    costBudgetInformationListClone() {
      return this.$store.state.procurementApprovalRfq.costBudgetInformationListClone
    }
  },
	methods: {
    formatCurrency,
    removeRow(i) {
      if (!this.dataProcurementApproval.costBudgetInformationList[i].id) {
        this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.filter((e,idx) => idx !== i)
      } else {
        const findIndex = this.costBudgetInformationListClone.findIndex(e => e.id === this.dataProcurementApproval.costBudgetInformationList[i].id)
        this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.map((e,i) => {
          if (this.dataProcurementApproval.costBudgetInformationList[i].id === this.costBudgetInformationListClone[findIndex].id) {
            return { ...this.costBudgetInformationListClone[findIndex], isDeleted: true }
          } else {
            return e
          }
        })
      }
    },
    addRow() {
      this.dataProcurementApproval.costBudgetInformationList = [ ...this.dataProcurementApproval.costBudgetInformationList, {
          id: null,
					costCenterId: '',
          costCenterSearch: '',
					wbsElement: '',
          wbsName: '',
          wbsElementSearch: '',
          coaCode: '',
          coaDescription: '',
          coaCodeSearch: '',
          budgetValueIdr: '',
          error: {
            "Cost Center": '',
            "Budget Value": '',
            }
				},]
    },
    totalBudget() {
      return this.dataProcurementApproval.costBudgetInformationList?.filter(e => !e.isDeleted)?.reduce((a,b) => +a + parseLocaleNumber(b.budgetValueIdr, 'idr'), 0) || 0
    },
    handleCheckLength(e, name, i) {
      const map = {
        "Cost Center": { field: 'costCenterId', lengthMax: 200, message: 'Maksimal 200 karakter' },
        "Budget Value": { field: 'budgetValueIdr', lengthMax: 14, message: 'Maksimal 14 digit' },
      }
      if (e.length > map[name].lengthMax) this.dataProcurementApproval.costBudgetInformationList[i].error[name] = map[name].message
      else if (this.dataProcurementApproval.costBudgetInformationList[i][map[name].field].length < 1) this.dataProcurementApproval.costBudgetInformationList[i].error[name] = 'Wajib Diisi'
      else this.dataProcurementApproval.costBudgetInformationList[i].error[name] = ''
    },
    validationNumber(e) {
      let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58)) {
				return
			}
			e.preventDefault();
    },
    inputBudgetValue(e, i) {
      const plainNumber = e.split('.').join('')
      this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.map((el, idx) => i === idx? ({ ...el, budgetValueIdr: !isNaN(+plainNumber) ? formatThousandSeparate(plainNumber, 'idr') : '' }) : el) 
      this.handleCheckLength(plainNumber, 'Budget Value', i)
    },
    handleInputBudgetValue(e) {
      const el = e.target;
			const formatted = formatThousandSeparate(parseLocaleNumber(el.value, 'idr'), 'idr')
			const count = formatted?.split('.').length
			const count2 = el.value?.split('.').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      });
		},
    async getMasterdataCostCenter(costCenterId) {
      const storeMasterdataCostCenter = await this.$store.dispatch('procurementApprovalRfq/getMasterdataCostCenter', { costCenterId })
      this.optionsCostCenter = storeMasterdataCostCenter.data.result
    },
    async getMasterdataWbsLev3Code(wbsElement) {
      const storeMasterdataWbsLev3 = await this.$store.dispatch('procurementApprovalRfq/getMasterdataWbsLev3Code', { wbsElement })
      this.optionsWbsLev3Code = storeMasterdataWbsLev3.data.result
    },
    async getMasterdataCoa(coaCode) {
      const storeMasterdataCoa = await this.$store.dispatch('procurementApprovalRfq/getMasterdataCoa', { coaCode })
      this.optionsCoa = storeMasterdataCoa.data.result
    },
    focusSearch(param, i) {
      if (this.dataProcurementApproval?.costBudgetInformationList.length -1 === i) {
        this.addHeight = 'h-40'
      }
      if (this.dataProcurementApproval?.costBudgetInformationList.length -2 === i) {
        this.addHeight = 'h-24'
      }
      if (this.dataProcurementApproval?.costBudgetInformationList.length -3 === i) {
        this.addHeight = 'h-10'
      }
      switch (param) {
        case 'costCenterId':
          this.getMasterdataCostCenter('')
          break;
        case 'wbsElement':
          this.getMasterdataWbsLev3Code('')
          break;
        case 'coaCode':
          this.getMasterdataCoa('')
          break;
        default:
          break;
      }
    },
    changeSearchCostCenter(e, j) {
      this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.map((l, idx) => idx === j ? ({ ...l, costCenterId: e.costCenterId, error: {...l.error, 'Cost Center': ''} }) : l  )
      this.dataProcurementApproval.costBudgetInformationList[j].costCenterSearch = e.costCenterId
    },
    changeSearchWbsLev3(e, j) {
      this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.map((l, idx) => idx === j ? ({ ...l, wbsElement: e.wbsElement, wbsName: e.name }) : l  )
      this.dataProcurementApproval.costBudgetInformationList[j].wbsElementSearch = e.wbsElement
    },
    changeSearchCoa(e, j) {
      this.dataProcurementApproval.costBudgetInformationList = this.dataProcurementApproval.costBudgetInformationList.map((l, idx) => idx === j ? ({ ...l, coaCode: e.coaCode, coaDescription: e.description }) : l  )
      this.dataProcurementApproval.costBudgetInformationList[j].coaCodeSearch = e.coaCode
    },
    inputSearchCostCenter(e) {
      this.getMasterdataCostCenter(e)
    },
    inputSearchWbsLev3Code(e) {
      this.getMasterdataWbsLev3Code(e)
    },
    inputSearchCoa(e) {
      this.getMasterdataCoa(e)
    },
    blurSearchCostCenter(i) {
      if (this.dataProcurementApproval.costBudgetInformationList[i].costCenterSearch.length === 0) {
        this.dataProcurementApproval.costBudgetInformationList[i].coaCostId = ''
      } else {
        this.dataProcurementApproval.costBudgetInformationList[i].costCenterSearch = this.dataProcurementApproval.costBudgetInformationList[i].costCenterId
      }
      this.handleCheckLength(0, 'Cost Center', i)
      this.addHeight = ''
    },
    blurSearchWbsElement(i) {
      if (this.dataProcurementApproval.costBudgetInformationList[i].wbsElementSearch.length === 0) {
        this.dataProcurementApproval.costBudgetInformationList[i].wbsElement = ''
        this.dataProcurementApproval.costBudgetInformationList[i].wbsName = ''
      } else {
        this.dataProcurementApproval.costBudgetInformationList[i].wbsElementSearch = this.dataProcurementApproval.costBudgetInformationList[i].wbsElement
      }
      this.addHeight = ''
    },
    blurSearchCoaCode(i) {
      if (this.dataProcurementApproval.costBudgetInformationList[i].coaCodeSearch.length === 0) {
        this.dataProcurementApproval.costBudgetInformationList[i].coaCode = ''
        this.dataProcurementApproval.costBudgetInformationList[i].coaDescription = ''
      } else {
        this.dataProcurementApproval.costBudgetInformationList[i].coaCodeSearch = this.dataProcurementApproval.costBudgetInformationList[i].coaCode
      }
      this.addHeight = ''
    }
	},
  components: {
    InputExtraSmall,
    AutocompleteNewSolutip
  }
}
</script>
<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-32 {
  min-width: 8rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-52 {
  min-width: 13rem;
}
.min-w-80 {
  min-width: 20rem;
}

</style>
